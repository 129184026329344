import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  List,
  message,
  Popconfirm,
  Table,
} from 'antd';
import {
  CheckOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';


const AttendanceTraineesRecordTable = ({
  trainees,
  modules,
  slots,
  setSlots,
  test,
  setTest
}) => {
  const { t } = useTranslation();
  




  const columns = [
    {
      title: 'Nom',
      render: trainee => `${trainee.last_name} ${trainee.first_name}`,
      key: 'name',
    },
    ...slots.map((slot, indexSlot) => ({
      title: (
        <>
          <span>{moment(slot.date).format('DD-MM-YYYY')}</span>
          <br />
          <span>{slot.type}</span>
          <br />
          <span>{slot.module}</span>
        </>
      ),
      key: slot.id,
      width: '122px',
      render: trainee =>{
        if (slot.signings.map(el => el.user).includes(trainee._id)){
          return( <CheckOutlined />)
        } else {
          return null;
        }
      }
    })),
  ];


  return (
    <>
      <Table
        columns={columns}
        scroll={{ x: 1500, y: 800 }}
        bordered
        dataSource={trainees}
        className="session-signings-table"
      />
    </>
  );
};
AttendanceTraineesRecordTable.propTypes = {
  trainees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modules: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AttendanceTraineesRecordTable;
