import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Steps,
  Card,
  Checkbox,
  Col,
  message,
  Rate,
  Row,
  List,
  Alert
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Radio from 'antd/es/radio/radio';
import useAuthContext from '../../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import ChoiceGridTable from './ChoiceGridTable';
import getQuizResponses from './utils/getQuizeResponses';
import getSessionName from './utils/getSessionName';
import getQuiz from './utils/getQuiz';
import createQuizResponse from './utils/createQuizResponse';

const { Step } = Steps;

const AnswerTrainingQuiz = () => {
  const { t } = useTranslation();
  const { id, sessionId } = useParams();
  const history = useHistory();
  const { dispatchAPI, user } = useAuthContext();
  const [quiz, setQuiz] = useState({});
  const [sessionName, setSessionName] = useState({});
  const [indexQuestion, setIndexQuestion] = useState(0);
  const [responses, setResponses] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [checked, setChecked] = useState([]);
  const [quizzesResponses, setQuizzesResponses] = useState([]);
  const [showRecap, setShowRecap] = useState(false);
  const [responsesErrors, setResponsesErrors] = useState([]);

  const nextQuestion = () => {
    setIndexQuestion(indexQuestion + 1);
    setCurrentStep(indexQuestion + 1);
  };

  useEffect(() => {
    const listResponses = {
      quiz: id,
      user: user._id,
      ref: user.ref,
      trainee_mark: 0,
      responses: []
    };
    quiz.questions &&
      quiz.questions.forEach(question => {
        if (question.type === 'CHOICE_GRID') {
          listResponses.responses.push({
            question: question._id,
            mark: 0,
            grid_responses_list: []
          });
        } else {
          listResponses.responses.push({
            question: question._id,
            response: []
          });
        }
      });
    quiz.questions &&
      quiz.questions.forEach(question => {
        question &&
          question.questions_list.forEach(el => {
            listResponses.responses.map((response, index) => {
              if (response.question.toString() === question._id.toString()) {
                listResponses.responses[index].grid_responses_list.push({
                  question: el._id,
                  response: [],
                  mark: 0
                });
              }
            });
          });
      });

    setResponses(listResponses);
  }, [quiz.questions]);

  const addResponse = (type, index, value, check) => {
    const listResponses = responses;
    let response = listResponses.responses[index];
    let listChecked = [...checked];

    if (type === 'MULTI_CHOICE') {
      if (check) {
        listResponses.responses[index].response.push(value);
        listChecked.push(value);
      } else {
        response = response.response.filter(el => el !== value);
        listResponses.responses[index].response = response;
        listChecked = listChecked.filter(el => el !== value);
      }
    } else {
      if (check) {
        listChecked.push(value);
      } else {
        listChecked = listChecked.filter(el => el !== value);
      }
      listResponses.responses[index].response = [value];
    }
    setChecked(listChecked);
    setResponses(listResponses);
  };

  const onStepChange = current => {
    setShowRecap(false);
    setCurrentStep(current);
    setIndexQuestion(current);
  };

  const noteCalculator = () => {
    const newResponses = { ...responses };
    let noteQuiz = 0;
    let questionLength = 0;
    responses.responses.forEach(response => {
      let noteQuestion = 0;
      const question = quiz.questions.find(el => el._id === response.question);
      const options =
        question.answer_options &&
        question.answer_options.filter(el => el.isRight).map(el => el._id);
      if (question.type === 'MULTI_CHOICE') {
        questionLength += 1;
        response.response.forEach(res => {
          if (response.response.length <= options.length) {
            if (options.includes(res)) {
              noteQuestion += question.mark / options.length;
            }
          } else if (response.response.length > options.length) {
            if (options.includes(res)) {
              noteQuestion += question.mark / question.answer_options.length;
            }
          }
        });
      } else if (question.type === 'UNIQUE_CHOICE') {
        questionLength += 1;
        response.response.forEach(res => {
          if (options.includes(res)) {
            noteQuestion += question.mark;
          }
        });
      }
      noteQuiz += noteQuestion;
    });
    newResponses.trainee_mark = noteQuiz;
    setResponses(newResponses);
    return newResponses;
  };

  const onSubmitResponses = async () => {
    const listErrors = [];
    const body = noteCalculator();
    responses.responses.map(response => {
      if (response.response && !response.response.length) {
        listErrors.push(response.question);
      } else if (response.grid_responses_list) {
        response.grid_responses_list.map(el => {
          if (!el.response.length) {
            listErrors.push(response.question);
          }
        });
      }
    });
    if (listErrors.length) {
      message.error(
        t('sessions.quizzes.message.missing_answers', {
          missing_answers: listErrors.length
        })
      );
    } else {
      body.session = id;
      await createQuizResponse({ body, dispatchAPI, t });
      await getQuizResponses({ setQuizzesResponses, dispatchAPI, quizId: id });
      history.goBack();
    }
    setResponsesErrors(listErrors);
  };

  const checkRender = (type, options, questionsList, id, questionIndex) => {
    let list = [];
    if (id) {
      list =
        responses.responses &&
        responses.responses.filter(
          el => el.question.toString() === id.toString()
        ) &&
        responses.responses
          .filter(el => el.question.toString() === id.toString())
          .map(el => el.response).length &&
        responses.responses
          .filter(el => el.question.toString() === id.toString())
          .map(el => el.response)[0];
    }
    switch (type) {
      case 'UNIQUE_CHOICE': {
        return (
          <>
            <Radio.Group
              onChange={value =>
                addResponse(
                  type,
                  questionIndex,
                  value.target.value,
                  value.target.checked
                )
              }
            >
              {options.map(el => (
                <Row>
                  <Col span={24}>
                    <Radio value={el._id}>{el.title}</Radio>
                  </Col>
                </Row>
              ))}
            </Radio.Group>
          </>
        );
      }
      case 'MULTI_CHOICE': {
        return (
          <>
            {options.map(el => (
              <Row>
                <Col span={24}>
                  <Checkbox
                    checked={checked.includes(el._id)}
                    value={el._id}
                    onChange={value =>
                      addResponse(
                        type,
                        questionIndex,
                        value.target.value,
                        value.target.checked
                      )
                    }
                  />
                  <span>{el.title}</span>
                </Col>
              </Row>
            ))}
          </>
        );
      }
      case 'TEXT_AREA': {
        return (
          <TextArea
            value={list[0]}
            placeholder={t('sessions.quizzes.placeholder.answer')}
            onChange={value =>
              addResponse(type, questionIndex, value.target.value)
            }
          />
        );
      }
      case 'SCORE': {
        return (
          <Rate
            value={list[0]}
            onChange={value => addResponse(type, questionIndex, value)}
          />
        );
      }
      case 'CHOICE_GRID': {
        return (
          <ChoiceGridTable
            indexQuestion={questionIndex}
            responses={responses}
            setResponses={setResponses}
            options={options}
            questionsList={questionsList}
          />
        );
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    (async () => {
      await getQuiz({ dispatchAPI, setQuiz, id });
      await getSessionName({ sessionId, dispatchAPI, setSessionName });
    })();
  }, []);

  const setStepStatus = response => {
    let type;
    if (response.response?.length) {
      type = 'process';
    } else {
      type = 'wait';
    }
    if (response.grid_responses_list) {
      response.grid_responses_list.map(el => {
        if (el.response.length) {
          type = 'process';
        } else {
          type = 'wait';
        }
      });
    }
    return type;
  };

  return (
    <>
      <PageHeaderCustom
        title={t('sessions.quizzes.title.name', {
          name: sessionName && sessionName.name
        })}
      />
      <ContentCustom>
        <Row>
          <Col span={4}>
            <Steps
              style={{
                height: '800px',
                position: 'fixed',
                maxHeight: '80vh',
                overflowY: 'auto',
                width: '50px'
              }}
              direction="vertical"
              current={currentStep}
              onChange={onStepChange}
            >
              {responses.responses &&
                responses.responses.map(response => (
                  <Step status={setStepStatus(response)} />
                ))}
            </Steps>
          </Col>
          <Col span={20}>
            {showRecap && (
              <Button
                style={{ marginBottom: '2%' }}
                type="add"
                onClick={() => onSubmitResponses()}
              >
                {t('sessions.quizzes.button.sendResponses')}
              </Button>
            )}
            <List
              grid={{ column: 1 }}
              dataSource={quiz.questions}
              renderItem={(item, index) => (
                <List.Item>
                  <Card
                    style={{
                      display: !showRecap
                        ? currentStep === index
                          ? ''
                          : 'none'
                        : '',
                      position: !showRecap ? 'fixed' : 'relative',
                      top: !showRecap ? '20vh' : '10%',
                      left: !showRecap ? '' : '',
                      width: '100%',
                      maxWidth: 700
                    }}
                    bordered={false}
                    className="quiz_question_card"
                    title={
                      <>
                        <div
                          style={{
                            fontSize: 17,
                            color: 'var(--primaryColor)',
                            borderRadius: '50%',
                            border: '1px solid var(--primaryColor)',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 30,
                            height: 30,
                            marginBottom: 10
                          }}
                        >
                          {index + 1}
                        </div>
                        <span style={{ marginLeft: 16 }}>{item.title}</span>
                      </>
                    }
                  >
                    <Row>
                      <Col span={24}>
                        {responsesErrors.includes(item._id) ? (
                          <Alert
                            type="error"
                            message={checkRender(
                              item.type,
                              item.answer_options,
                              item.questions_list,
                              item._id,
                              index
                            )}
                          />
                        ) : (
                          checkRender(
                            item.type,
                            item.answer_options,
                            item.questions_list,
                            item._id,
                            index
                          )
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {quiz.questions &&
                        !showRecap &&
                        indexQuestion < quiz.questions.length - 1 ? (
                          <Button
                            style={{ float: 'right', marginTop: 20 }}
                            type="primary"
                            onClick={() => nextQuestion()}
                          >
                            {t('sessions.quizzes.button.nextQuestion')}
                          </Button>
                        ) : (
                          !showRecap && (
                            <Button
                              style={{ float: 'right', marginTop: 20 }}
                              type="primary"
                              onClick={() => setShowRecap(!showRecap)}
                            >
                              {t('sessions.quizzes.button.end')}
                            </Button>
                          )
                        )}
                      </Col>
                    </Row>
                  </Card>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};

export default AnswerTrainingQuiz;

