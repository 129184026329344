import React, { createContext, useContext, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useStateWithLocalStorage } from '../utils';

const AuthContext = createContext({});
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' }
});

export const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [rememberMe, setRememberMe] = useStateWithLocalStorage('remember_me');
  const [user, setUser] = useStateWithLocalStorage('user', {
    first_name: 'John',
    last_name: 'Doe',
    role: 'admins:ADMIN'
  });
  const [token, setToken] = useStateWithLocalStorage('token');

  // TODO: Change initial state to false
  const [isValid, setIsValid] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const setSession = accessToken => {
    if (accessToken) {
      setToken(accessToken);
      setIsValid(true);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      setToken(null);
      setIsValid(false);
      delete axiosInstance.defaults.headers.common.Authorization;
    }
  };

  const setUserSession = userExtra => {
    if (userExtra) {
      setUser(userExtra);
    } else {
      setUser(null);
    }
  };
  const loginAPI = async (
    email,
    password,
    remember,
    userExtranet,
    userRole,
    session
  ) => {
    try {
      const result = await axiosInstance.post(
        `/login?purpose=EXTRANET&userRole=${userRole}&organization=${session.organization}`,
        {
          email,
          password
        }
      );
      setUser(userExtranet);
      setRememberMe(remember);
      setSession(result.data.token);
      return result;
    } catch (e) {
      return throw e;
    }
  };

  const googleLoginAPI = async code => {
    try {
      const result = await axiosInstance.post('/auth2/google', { code });
      setUser(result.data.user);
      setRememberMe(true);
      setSession(result.data.token);
      return result;
    } catch (e) {
      return throw e;
    }
  };

  const facebookLoginAPI = async options => {
    try {
      const result = await axiosInstance.post('/auth2/facebook', { options });
      setUser(result.data.user);
      setRememberMe(true);
      setSession(result.data.token);
      return result;
    } catch (e) {
      return throw e;
    }
  };

  const twitterLoginAPI = async options => {
    try {
      const result = await axiosInstance.post('/auth2/twitter', options);
      setUser(result.data.user);
      setRememberMe(true);
      setSession(result.data.token);
      return result;
    } catch (e) {
      return throw e;
    }
  };

  const linkedinLoginAPI = async options => {
    try {
      const result = await axiosInstance.post('/auth2/linkedin', options);
      setUser(result.data.user);
      setRememberMe(true);
      setSession(result.data.token);
      return result;
    } catch (e) {
      return throw e;
    }
  };

  const getUserByEmail = async (email, userRole, session) => {
    try {
      return await axiosInstance.get(
        `/register?email=${email}&userRole=${userRole}&organization=${session.organization}`
      );
    } catch (e) {
      return throw e;
    }
  };

  const createUserPassword = async (url, body) => {
    try {
      return await axiosInstance.patch(url, body);
    } catch (e) {
      return throw e;
    }
  };

  const registerAPI = async values => {
    try {
      return await axiosInstance.post(
        `/register?idSession=${values.idSession}`,
        values
      );
    } catch (e) {
      return throw e;
    }
  };

  const logout = () => {
    setSession(null);
    setUser(null);
  };

  const isTokenValid = () => {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        message.warn(t('login.expiredSession'));
        setSession(null);
        return false;
      }
    } catch (e) {
      message.warn(t('login.tokenError'));
      setSession(null);
      return false;
    }
    if (!isValid) {
      setIsValid(true);
    }
    return true;
  };

  isTokenValid();

  const fetchAPI = async (
    url,
    method = 'GET',
    body = null,
    responseType = 'json',
    cancelToken
  ) => {
    try {
      isTokenValid();
      setIsLoading(true);
      const result = await axiosInstance({
        url,
        method,
        responseType,
        cancelToken,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setIsLoading(false);
      return result;
    } catch (e) {
      setIsLoading(false);
      return throw e;
    }
  };

  const dispatchAPI = (type, options) => {
    switch (type) {
      case 'LOGIN':
        return loginAPI(
          options.email,
          options.password,
          options.rememberMe,
          options.userExtranet,
          options.userRole,
          options.session
        );
      case 'GOOGLE_LOGIN':
        return googleLoginAPI(options.code);
      case 'FACEBOOK_LOGIN':
        return facebookLoginAPI(options);
      case 'TWITTER_LOGIN':
        return twitterLoginAPI(options);
      case 'LINKEDIN_LOGIN':
        return linkedinLoginAPI(options);
      case 'REGISTER':
        return registerAPI(options);
      case 'LOGOUT':
        return logout();
      case 'GET':
        return fetchAPI(
          options.url,
          'GET',
          null,
          options.responseType,
          options.cancelToken
        );
      case 'GET_USER_BY_EMAIL':
        return getUserByEmail(options.email, options.userRole, options.session);
      case 'DELETE':
        return fetchAPI(options.url, 'DELETE');
      case 'POST':
      case 'PATCH':
        return fetchAPI(options.url, type, options.body);
      case 'CREATE_USER_PWD':
        return createUserPassword(options.url, options.body);
      default:
        return throw new Error('Unknown dispatchAPI type!');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        token,
        isValid,
        dispatchAPI,
        isLoading,
        setSession,
        setUserSession
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default () => useContext(AuthContext);

