/* eslint-disable no-underscore-dangle */
const checkIfSignings = ({ signings, user }) => {
  let check = false;
  if (signings) {
    signings.forEach(sign => {
      if (sign.user?.toString() === user._id.toString()) {
        check = true;
      }
    });
  }
  return check;
};

const setTreeData = ({
  user,
  modules,
  purpose,
  moment,
  checkedKeys,
  setSlots
}) => {
  const modulesList = [];
  const treeData = [];
  if (user.role !== 'contributor') {
    modulesList.push(...modules);
  } else if (user.role === 'contributor' && purpose === 'agenda') {
    modulesList.push(...modules);
  } else if (user.role === 'contributor' && purpose !== 'agenda') {
    modules.forEach(module => {
      const dates = [];
      module.slots.date.forEach(d => {
        const subSlots = [];
        d.sub_slots.forEach(subSlot => {
          if (subSlot.contributors.includes(user._id)) {
            subSlots.push(subSlot);
          }
        });
        if (subSlots.length) {
          dates.push({
            ...d,
            sub_slots: subSlots
          });
        }
      });
      if (dates.length) {
        modulesList.push({
          ...module,
          slots: {
            ...modulesList.slots,
            date: dates
          }
        });
      }
    });
  }
  modulesList.forEach(module => {
    treeData.push({
      title: module.name,
      id: module._id,
      key: module._id,
      children: [],
      disableCheckbox: true
    });
  });
  modulesList.forEach(module => {
    treeData.forEach((item, index) => {
      if (module._id === item.key) {
        module.slots.date
          .sort((a, b) => (moment(a.date).isAfter(b.date) ? 1 : -1))
          .forEach(date => {
            treeData[index].children.push({
              title: `${moment(date.date).format('dddd')} ${moment(
                date.date
              ).format('LL')}`,
              key: date._id,
              disableCheckbox: true,
              children: []
            });
          });
      }
    });
  });
  modulesList.forEach(module => {
    module.slots.date.forEach(date => {
      treeData.forEach((item, index) => {
        item.children.forEach((el, indexChild) => {
          if (el.key === date._id) {
            date.sub_slots.forEach(subSlot => {
              if (checkIfSignings({ signings: subSlot.signings, user })) {
                checkedKeys.push(subSlot._id);
                treeData[index].children[indexChild].children.push({
                  title: `${subSlot.start_time} - ${subSlot.end_time} - signée`,
                  contributors: subSlot.contributors,
                  key: subSlot._id
                });
              } else if (moment(el.title, 'dddd D MMMM YYYY') > moment()) {
                treeData[index].children[indexChild].children.push({
                  title: `${subSlot.start_time} - ${subSlot.end_time}`,
                  contributors: subSlot.contributors,
                  key: subSlot._id,
                  disableCheckbox: true
                });
              } else {
                treeData[index].children[indexChild].children.push({
                  title: `${subSlot.start_time} - ${subSlot.end_time}`,
                  contributors: subSlot.contributors,
                  key: subSlot._id
                });
              }
            });
          }
        });
      });
    });
  });
  setSlots(treeData);
};

export default setTreeData;
