import React, { useEffect, useState } from 'react';
import Radio from 'antd/es/radio/radio';
import PropTypes from 'prop-types';
import { Button, Card, Checkbox, Col, List, message, Rate, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined, LeftOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import { PageHeaderCustom } from '../../../components';
import { routes } from '../../../utils/constants/routes';
import ChoiceGridTable from './ChoiceGridTable';

const DetailQuiz = ({ purpose, response }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const [quizResponses, setQuizResponses] = useState({});

  const getQuizResponses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `quizzes_responses/${id}?populate=quiz`
      });
      setQuizResponses(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getQuizResponses();
    })();
  }, []);

  const checkRender = (type, options, indexQuestion, item) => {
    const list =
      purpose === 'modal'
        ? response.responses
            .filter(el => el.question.toString() === item._id.toString())
            .map(el => el.response)[0]
        : quizResponses.responses
            .filter(el => el.question.toString() === item._id.toString())
            .map(el => el.response)[0];
    const listOptions = item.answer_options
      .filter(el => el.isRight)
      .map(el => el._id);

    switch (type) {
      case 'UNIQUE_CHOICE': {
        return (
          <>
            {options.map(el => {
              return (
                <Row>
                  <Col span={24}>
                    {purpose === 'training_quiz' &&
                      (listOptions.includes(el._id) ? (
                        <CheckOutlined style={{ color: 'green' }} />
                      ) : (
                        <CloseOutlined style={{ color: 'red' }} />
                      ))}
                    <Radio
                      disabled
                      checked={list.includes(el._id)}
                      value={el._id}
                    />
                    <span>{el.title}</span>
                  </Col>
                </Row>
              );
            })}
          </>
        );
      }
      case 'MULTI_CHOICE': {
        return (
          <>
            {options.map(el => (
              <Row>
                <Col span={24}>
                  {purpose === 'training_quiz' &&
                    (listOptions.includes(el._id) ? (
                      <CheckOutlined style={{ color: 'green' }} />
                    ) : (
                      <CloseOutlined style={{ color: 'red' }} />
                    ))}
                  <Checkbox checked={list.includes(el._id)} disabled />
                  <span>{el.title}</span>
                </Col>
              </Row>
            ))}
          </>
        );
      }
      case 'TEXT_AREA':
        return <TextArea value={list[0]} disabled />;
      case 'SCORE':
        return <Rate value={list[0]} disabled />;
      case 'CHOICE_GRID': {
        return (
          <ChoiceGridTable
            indexQuestion={indexQuestion}
            options={options}
            responses={purpose === 'modal' ? response : quizResponses}
            questionsList={item.questions_list}
            purpose="show"
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <>
      {purpose !== 'modal' && (
        <PageHeaderCustom
          title={t('sessions.quizzes.title.name', {
            name:
              purpose === 'modal'
                ? response.quiz.name
                : quizResponses.quiz && quizResponses.quiz.name
          })}
        />
      )}

      <Card style={{ marginTop: '20px' }} className="quiz-detail-wrapper">
        <List
          grid={{ column: 1 }}
          dataSource={
            purpose === 'modal'
              ? response.quiz.questions
              : quizResponses.quiz?.questions
          }
          renderItem={(item, index) => (
            <List.Item>
              <Card bordered={false} title={item.title}>
                {checkRender(item.type, item.answer_options, index, item)}
              </Card>
            </List.Item>
          )}
        />
        {purpose !== 'modal' && (
          <Button
            type="primary"
            ghost
            onClick={() =>
              history.push(
                `${routes.SESSIONS}/show/${quizResponses.quiz.session}`
              )
            }
          >
            <LeftOutlined />
            {t('buttons.back')}
          </Button>
        )}
      </Card>
    </>
  );
};

export default DetailQuiz;

DetailQuiz.propTypes = {
  purpose: PropTypes.string,
  response: PropTypes.shape({
    responses: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
    quiz: PropTypes.shape({
      name: PropTypes.string,
      questions: PropTypes.arrayOf(PropTypes.shape({}))
    })
  })
};

DetailQuiz.defaultProps = {
  purpose: '',
  response: {}
};
