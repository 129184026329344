import { message } from 'antd';

const getQuiz = async ({ dispatchAPI, setQuiz, id }) => {
  try {
    const { data } = await dispatchAPI('GET', { url: `quizzes/${id}` });
    setQuiz(data);
  } catch (e) {
    if (e.response) message.error(e.response.status);
  }
};

export default getQuiz;
