import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';

const LoginForm = ({ switchForm, checkEmailCustomer, from, session }) => {
  const { isValid, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const loginUser = async (
    email,
    password,
    rememberMe,
    userExtranet,
    userRole
  ) => {
    setLoading(true);
    try {
      await dispatchAPI('LOGIN', {
        email,
        password,
        rememberMe,
        userExtranet,
        userRole,
        session
      });
      message.success(t('login.success'));
    } catch (e) {
      if (e.response) {
        if (e.response.data && e.response.data.error) {
          message.error(e.response.data.error.description);
        } else {
          message.error(t('login.wrongDetails'));
        }
      } else if (e.request) {
        message.error(t('login.networkError'));
      } else {
        message.error(t('login.error'));
      }
      setLoading(false);
    }
  };

  const handleSubmit = async values => {
    const customers = checkEmailCustomer(values.username);

    if (customers.length > 0) {
      const customer = customers.find(el => el.email === values.username);
      if (customer.verifiedEmail) {
        const userRole = customer.role;
        await loginUser(
          values.username.toLowerCase(),
          values.password,
          values.remember,
          customer,
          userRole
        );
      } else {
        message.error(t('errors.message.emailNotVerified'), 10);
      }
    } else {
      message.error(t('errors.message.wrongEmail'), 10);
    }
  };

  return (
    <Form onFinish={handleSubmit} initialValues={{ remember: true }}>
      {isValid && <Redirect to={from} />}
      <Form.Item
        name="username"
        rules={[{ required: true, message: t('login.usernameMissing') }]}
      >
        <Input
          prefix={
            <UserOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.username')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: t('login.pwdMissing') }]}
      >
        <Input.Password
          prefix={
            <LockOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.password')}
        />
      </Form.Item>
      <Form.Item style={{ textAlign: 'left' }}>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>{t('login.rememberMe')}</Checkbox>
        </Form.Item>
        <Button
          type="link"
          style={{ float: 'right', padding: 0, height: 'auto' }}
          onClick={() => switchForm('forgotPwd')}
        >
          {t('login.forgotPwd')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          {t('login.connect')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="link"
          style={{ width: '100%' }}
          onClick={() => switchForm('createPwd')}
        >
          {t('login.firstConnection')}
        </Button>
      </Form.Item>
    </Form>
  );
};

LoginForm.propTypes = {
  switchForm: PropTypes.func.isRequired,
  checkEmailCustomer: PropTypes.func.isRequired,
  from: PropTypes.shape({}).isRequired,
  session: PropTypes.shape({}).isRequired
};

export default LoginForm;
