import React from 'react';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useColumns from './columns';
import useAuthContext from '../../contexts/AuthContext';

const Sessions = () => {
  const columns = useColumns();
  const { user } = useAuthContext()

  //customers.customer=${user._id}||
  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="sessions"
      extraQuery={`session=${user._id}`}
      withSubRoutes
    />
  )
}

export default Sessions;
