import React, { useEffect } from 'react';
import { Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import img404 from '../../assets/images/404.svg';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';

const StyledException = styled.div`
  width: 100%;
  text-align: center;
`;

const StyledImgBlock = styled.div`
  width: 50%;
  margin: auto;
`;

const ExceptionTitle = styled.h1`
  color: #434e59;
  font-weight: 600;
  font-size: 72px;
`;

const Exception = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { setSession, setUserSession } = useAuthContext();
  const urlSplit = pathname.split("/");
  
  useEffect(() => {
    if( urlSplit[1] === 'extranet' ){
      setSession();
      setUserSession();
    }
  }, [])
  
  
  return (
    <StyledException>
      <ExceptionTitle>404</ExceptionTitle>
      <div style={{ fontSize: 20, marginBottom: 20 }}>
        {t('404.message')}
        &nbsp;
        <Link to="/">
          <Button type="primary">{t('404.button')}</Button>
        </Link>
      </div>
      <StyledImgBlock>
        <img src={img404} alt=""/>
      </StyledImgBlock>
    </StyledException>
  );
};

export default Exception;
