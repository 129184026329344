import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { outOfNavRoutes, routes } from '../utils/constants/routes';
import Login from './login';
import Home from './home/Home';
import Exception from '../components/Exceptions/Exception';
import useAuthContext from '../contexts/AuthContext';
import BasicLayout from '../components/layouts/BasicLayout';
import Confirmation from './emailConfirmation/Confirmation';
import Extranet from './Extranet/Extranet';
import SessionsRouter from './Sessions/SessionsRouter';

export const PrivateRoute = ({
  location,
  component: Component,
  ...restProps
}) => {
  const [isAllowed, setIsAllowed] = useState('');
  const [sessionData, setSessionData] = useState('no-session');
  const authContext = useAuthContext();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const splitPath = location.pathname.split('/');
  const path = `/${
    splitPath[2] && !['show', 'edit', 'create'].includes(splitPath[2])
      ? splitPath[2]
      : splitPath[1]
  }`;
  const test = splitPath[3];

  const getSession = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/extranet/${test}?customer=${JSON.stringify([
          { path: 'contributors', populate: { path: 'contributor' } },
          {
            path: 'customers',
            populate: [{ path: 'customer' }, { path: 'trainees_list' }]
          }
        ])}`
      });
      setSessionData(data);
    } catch (e) {
      message.error(t('sessions.error'));
    }
  };

  const checkIsValid = async () => {
    const userId = authContext.user._id;
    const searchParams = new URLSearchParams(location.search);
    const guestId = searchParams.get('guestId');
    let userType;

    switch (authContext.user.role) {
      case 'contributor':
        userType = 'contributors';
        break;
      case 'company':
        userType = 'companies';
        break;
      case 'trainee':
        userType = 'trainees';
        break;
      default:
        userType = 'users';
    }

    try {
      if (userType) {
        const { data } = await dispatchAPI('GET', {
          url: `/${userType}/${userId}?populate=organization`
        });
        const stringSession = JSON.stringify(sessionData);

        const valid = stringSession.includes(data.organization._id);

        if (guestId && guestId !== userId) {
          setIsAllowed(false);
        } else {
          setIsAllowed(valid);
        }
      }
    } catch (e) {
      setIsAllowed(false);
    }
  };

  useEffect(() => {
    if (splitPath[2] === 'sessions' || splitPath[1] === 'sessions') {
      if (!test) return;
      getSession();
    } else {
      setIsAllowed(true);
    }
  }, [test]);

  useEffect(() => {
    if (sessionData !== 'no-session') checkIsValid();
  }, [sessionData]);

  return (
    <Route
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restProps}
      render={props => {
        if (isAllowed === false) {
          dispatchAPI('LOGOUT');
          message.error(t('login.forcedLogout'));
          return (
            <Redirect
              to={{
                pathname: outOfNavRoutes.LOGIN,
                state: { from: props.location }
              }}
            />
          );
        }
        if (authContext.isValid) {
          return (
            <BasicLayout path={path}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Component {...props} />
            </BasicLayout>
          );
        }
        if (!authContext.isValid && splitPath[1] === 'extranet') {
          return <Extranet id={splitPath[2]} />;
        }
        if (!authContext.isValid) {
          return (
            <Redirect
              to={{
                pathname: outOfNavRoutes.LOGIN,
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

PrivateRoute.defaultProps = {
  location: { pathname: '/' }
};

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} component={Login} />
        <Route path={outOfNavRoutes.LOGIN} component={Login} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          component={Confirmation}
        />
        <Route exact path="/" component={Home} />
        <PrivateRoute component={SessionsRouter} path={`${routes.SESSIONS}`} />

        <PrivateRoute component={Exception} />
        <PrivateRoute component={Extranet} path="/extranet/:id" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;

