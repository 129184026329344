import { message } from 'antd';

const getQuizResponses = async ({
  setQuizzesResponses,
  dispatchAPI,
  quizId
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `quizzes_responses?quizId=${quizId}`
    });
    setQuizzesResponses(data);
  } catch (e) {
    if (e.response) message.error(e.response.status);
  }
};

export default getQuizResponses;

