import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  Collapse,
  Divider,
  List,
  PageHeader,
  Row,
  Space,
  Spin,
  Switch
} from 'antd';
import {
  CalendarOutlined,
  DownloadOutlined,
  FileOutlined
} from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import ModulesTree from './modulesTree';
import ModulesCalendar from './modulesCalendar';
import useAuthContext from '../../contexts/AuthContext';

const SwitchEdition = styled.div`
  font-size: 16px;
  color: var(--primaryColor);
  .anticon {
    margin-right: 10px;
  }
  .ant-switch {
    margin-left: 10px;
    margin-bottom: 5px;
  }
  .ant-switch-checked {
    background-color: var(--primaryColor);
  }
`;

const { Panel } = Collapse;
const TrainingHome = ({
  reload,
  setReload,
  modules,
  visibilityOptions,
  downloadFile,
  downloadRulesFile,
  files,
  generatingInternalRulesStatus
}) => {
  const { t } = useTranslation();
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [planningView, setPlanningView] = useState('tree');
  const { user } = useAuthContext();

  const planningTitle = (
    <>
      <CalendarOutlined style={{ fontSize: 18, marginRight: 8 }} />
      {` ${moment(dateStart).format('DD-MM-YYYY')} -  ${moment(dateEnd).format(
        'DD-MM-YYYY'
      )}`}
    </>
  );

  return (
    <>
      {modules.length &&
        visibilityOptions &&
        visibilityOptions.online_signings_button &&
        user.role !== 'company' && (
          <>
            <Divider orientation="left" style={{ marginTop: 20 }}>
              <PageHeader title="Emargements" />
            </Divider>
            <Card>
              <ModulesTree
                setReload={setReload}
                reload={reload}
                modules={modules}
                setDateEnd={setDateEnd}
                setDateStart={setDateStart}
                purpose="signings"
              />
            </Card>
          </>
        )}

      {modules.length && visibilityOptions && visibilityOptions.agenda && (
        <>
          <Divider orientation="left">
            <span>Dates</span>
          </Divider>
          <ContentCustom>
            <Card
              bordered
              title={planningTitle}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column'
              }}
              style={{ margin: 0 }}
            >
              <Row gutter={16} justify="end">
                <Space>
                  <SwitchEdition>
                    <span>Vue calendrier</span>
                    <Switch
                      onChange={() => {
                        if (planningView === 'tree') {
                          setPlanningView('calendar');
                        } else setPlanningView('tree');
                      }}
                    />
                  </SwitchEdition>
                </Space>
              </Row>
              <Row gutter={16}>
                {planningView === 'tree' ? (
                  <ModulesTree
                    modules={modules}
                    setDateEnd={setDateEnd}
                    setDateStart={setDateStart}
                    purpose="agenda"
                  />
                ) : (
                  <ModulesCalendar modules={modules} />
                )}
              </Row>
            </Card>
          </ContentCustom>
        </>
      )}
      {visibilityOptions && visibilityOptions.internal_rules && (
        <>
          <Divider orientation="left" style={{ marginTop: 24 }}>
            <PageHeader title="Télécharger le règlement intérieur" />
          </Divider>
          <ContentCustom>
            <Button
              disabled={generatingInternalRulesStatus}
              type="primary"
              onClick={async () => {
                await downloadRulesFile();
              }}
            >
              Télécharger le règlement intérieur
            </Button>
            {generatingInternalRulesStatus && (
              <Spin style={{ marginLeft: 10 }} />
            )}
          </ContentCustom>
        </>
      )}
    </>
  );
};

TrainingHome.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  visibilityOptions: PropTypes.shape({}).isRequired,
  downloadFile: PropTypes.func.isRequired,
  downloadRulesFile: PropTypes.func.isRequired
};
export default TrainingHome;
