import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import useHandleResize from '../../utils/HandleResize';
import UpdateAlert from './UpdateAlert';

const { Content, Footer } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const StyledContent = styled.div`
  margin: 64px 0 0 0;
  padding-right: 0;
  overflow: hidden;
  :hover {
    overflow-y: overlay;
  }
  @media (max-width: 992px) {
    margin-left: 0;
  }

  @media (max-width: 576px) {
    margin-left: 0;
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 25px;
  text-align: center;
  margin-left: 255px;
  background-color: #f5f5f5;
  color: var(--textColorSecondary);
  box-shadow: 0 -1px 8px rgba(43, 43, 43, 0.15);
  font-weight: 500;
  @media (max-width: 992px) {
    margin-left: 80px;
  }
  @media (max-width: 576px) {
    margin-left: 0;
  }
  a {
    color: var(--textColor);
    font-weight: 600;
  }
`;

const BasicLayout = ({ children, path }) => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <StyledLayout as={Layout}>
      <UpdateAlert />
      <Layout>
        <HeaderLayout
          collapsed={collapsed}
          collapseSider={setCollapsed}
          collapseWidth={collapseWidth}
        />
        <StyledContent as={Content}>{children}</StyledContent>
        <StyledFooter as={Footer}>
          {`Extranet `}
          <a href="https://demateriz.com">Demateriz</a>
          {` ©${moment().year()} powered by `}
          <a href="https://strateg.in">Strateg.in</a>
        </StyledFooter>
      </Layout>
    </StyledLayout>
  );
};

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,

  path: PropTypes.string.isRequired
};

export default BasicLayout;

