import React from 'react';

const useListContent = ({
  name,
  subtitle,
  description,
  training_action_type
}) => {

  return [
    {
      label: 'programs.form.name',
      content: name,
    },
    {
      label: 'programs.form.subtitle',
      content: subtitle,
      span: 2
    },
    {
      label: 'programs.form.training_type',
      content:  training_action_type && training_action_type.training_action_type
    },
    {
      label: 'programs.form.training_domain',
      content:  training_action_type && training_action_type.training_domain
    },
    {
      label: 'programs.form.diploma',
      content:  training_action_type && training_action_type.diploma
    },
    {
      label: 'programs.form.description',
      content: description,
      span: 3
    },

  ];
};

export default useListContent;
