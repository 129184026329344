import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { message, Tabs, PageHeader } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import TrainingHome from './TrainingHome';
import TraineesList from './TraineesList';
import Program from './program/Program';
import Quizzes from './quizzes/Quizzes';
import Documents from './Documents';

const { TabPane } = Tabs;

const SessionRedirection = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams();
  const { dispatchAPI, user } = useAuthContext();
  const [isDownloading, setIsDownloading] = useState(false);
  const [reload, setReload] = useState(false);
  const [modules, setModules] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const [traineesList, setTraineesList] = useState([]);
  const [quizzesResponses, setQuizzesResponses] = useState([]);
  const [files, setFiles] = useState([]);
  const [session, setSession] = useState({});
  const [idSession, setIdSession] = useState('');
  const [visibilityOptions, setVisibilityOptions] = useState({});
  const [quizzes, setQuizzes] = useState([]);
  const [quizzesModel, setQuizzesModel] = useState([]);
  const token = location.state;
  const [program, setProgram] = useState({});
  const [
    generatingInternalRulesStatus,
    setGeneratingInternalRulesStatus
  ] = useState(false);

  const getQuizzes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quizzes?populate=session&userRole=${user.role}&session=${id}`
      });
      setQuizzes(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getQuizzes();
    })();
  }, []);

  useEffect(() => {
    if (token) {
      setIdSession(token.session?._id);
      setFiles(files && token.session?.files);
    } else {
      setIdSession(id);
    }
  }, [token, id]);

  const downloadCGVFile = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=cgv&organization=${user.organization}`
      });
      const response = await dispatchAPI('GET', {
        url: `/files/cgv/${data[0]._id}?fileType=pdf`
      });
      const bufferArray = new Uint8Array(response.data.data);
      const blob = new Blob([bufferArray], {
        type: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${data[0].metadata.originalName.split('.')[0]}.pdf`;
      a.click();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    if (user.role === 'trainee') {
      setVisibilityOptions(
        session?.extranet_visibility_options?.trainees_visibility_options
      );
    } else if (user.role === 'contributor') {
      setVisibilityOptions(
        session?.extranet_visibility_options?.contributors_visibility_options
      );
    } else if (user.role === 'company') {
      setVisibilityOptions(
        session?.extranet_visibility_options?.companies_visibility_options
      );
    } else {
      setVisibilityOptions(
        session?.extranet_visibility_options?.trainees_visibility_options
      );
    }
  }, [session]);

  useEffect(() => {
    if (session && session?.files) {
      setFiles(
        session?.files?.filter(
          file =>
            file.type === 'extranet' ||
            (file.user === user._id &&
              (file.type !== 'subcontracting' ||
                (visibilityOptions &&
                  visibilityOptions.subcontracting_contract)))
        )
      );
    }
  }, [visibilityOptions]);

  const getSession = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `sessions/${id}?customer=${JSON.stringify([
          { path: 'program' },
          { path: 'contributors', populate: { path: 'contributor' } },
          {
            path: 'customers',
            populate: [{ path: 'customer' }, { path: 'trainees_list' }]
          }
        ])}`
      });
      setSession(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const getModules = async () => {
    try {
      const filter = { session: id };
      const { data } = await dispatchAPI('GET', {
        url: `module?session=${id}`
      });
      const list = [...data];
      data.map((module, index) => {
        module.slots.date.map((date, indexDate) => {
          if (!date.sub_slots.length) {
            list[index].slots.date.splice(indexDate, 1);
          }
        });
      });
      setModules(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const getTrainees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'trainees' });
      setTrainees(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const getQuizzesResponses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'quizzes_responses?populate=quiz'
      });
      setQuizzesResponses(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    if (user.ref) {
      switch (user.ref) {
        case 'Contributor':
          setQuizzesModel(['contributors_quizzes']);
          break;
        case 'Company':
          setQuizzesModel(['managers_quizzes']);
          break;
        case 'Trainees':
          setQuizzesModel([
            'training_quiz',
            'pre_training',
            'post_training_j',
            'post_training_month'
          ]);
          break;
      }
    }
  }, [user]);

  useEffect(() => {
    if (session.program) {
      setProgram({
        ...session.program,
        educational_goals: session.program.educational_goals.filter(el => el),
        trainees_profile: {
          customers: session.program.trainees_profile.customers.filter(
            el => el
          ),
          prerequisite: session.program.trainees_profile.prerequisite.filter(
            el => el
          )
        },
        execution_track: {
          ...session.program.execution_track,
          results_track: session.program.execution_track.results_track.filter(
            el => el
          ),
          educational_resources: session.program.execution_track.educational_resources.filter(
            el => el
          )
        }
      });
    }
  }, [session?.program]);

  useEffect(() => {
    (async () => {
      await getSession();
      await getModules();
      await getTrainees();
      await getQuizzesResponses();
    })();
  }, [reload]);

  useEffect(() => {
    const list = [];
    session &&
      session.customers &&
      session.customers
        .filter(({ status, ...el }) =>
          user.role === 'contributor' ? status === 'ACCEPTED' : el
        )
        .forEach(customer => {
          if (customer.ref === 'Trainees' && user.role === 'contributor') {
            list.push(customer.customer);
          } else if (customer.ref === 'Company') {
            list.push(...customer.trainees_list);
          }
        });
    setTraineesList(list);
  }, [session, trainees]);

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const downloadRulesFile = async () => {
    try {
      setGeneratingInternalRulesStatus(true);
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=internal_rules&organization=${user.organization}`
      });
      let response;
      let filename;
      if (data.length === 1 && !session.internal_rules) {
        response = await dispatchAPI('GET', {
          url: `/files/cgv/${data[0]._id}?fileType=pdf&purpose=internal_rules`
        });
        filename = data[0].metadata.originalName.split('.')[0];
      } else if (session.internal_rules) {
        response = await dispatchAPI('GET', {
          url: `/files/cgv/${session.internal_rules._id}?fileType=pdf&purpose=internal_rules`
        });
        filename = session.internal_rules.filename.split('.')[0];
      }
      if (response) {
        const bufferArray = new Uint8Array(response.data.data);
        const blob = new Blob([bufferArray], {
          type: 'application/pdf'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${filename}.pdf`;
        a.click();
      } else {
        message.error(
          "Aucun réglement intérieur n'est disponible, veuillez contacter l'administrateur de la formation"
        );
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setGeneratingInternalRulesStatus(false);
    setIsDownloading({
      ...isDownloading,
      [session?.internal_rules?._id]: false
    });
  };

  return (
    <>
      <PageHeader
        title={t('sessions.show.name', {
          name: session?.name
        })}
      />
      <Tabs defaultActiveKey="1" className="session-tabs">
        <TabPane tab={"Page d'accueil de la formation"} key="1">
          <TrainingHome
            reload={reload}
            setReload={setReload}
            downloadFile={downloadFile}
            downloadRulesFile={downloadRulesFile}
            session={session}
            files={files}
            modules={modules}
            generatingInternalRulesStatus={generatingInternalRulesStatus}
            visibilityOptions={visibilityOptions}
          />
        </TabPane>
        {user.role !== 'trainee' && (
          <TabPane tab="Stagiaires" key="2">
            <TraineesList
              trainees={traineesList}
              modules={modules}
              quizzesResponses={quizzesResponses}
            />
          </TabPane>
        )}
        {visibilityOptions && visibilityOptions.program && session.program && (
          <TabPane tab="Programme" key="3">
            <Program program={program} />
          </TabPane>
        )}
        {quizzes.filter(el => quizzesModel.includes(el.type)).length > 0 && (
          <TabPane tab="Evaluations" key="4">
            <Quizzes
              quizzesModel={quizzesModel}
              setQuizzesModel={setQuizzesModel}
              quizzesResponses={quizzesResponses}
              quizzes={quizzes}
            />
          </TabPane>
        )}
        {!!files.length && (
          <TabPane tab="Documents" key="5">
            <Documents
              downloadFile={downloadFile}
              files={files}
              downloadCGVFile={downloadCGVFile}
            />
          </TabPane>
        )}
      </Tabs>
    </>
  );
};

export default SessionRedirection;
