import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Layout, message, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
import useHandleResize from '../../utils/HandleResize';
import ChangePwdForm from './ChangePwdForm';
import CreatePwdForm from './CreatePwdForm';
import Logo from '../../assets/images/demateriz-logo-gray.svg';
import useAuthContext from '../../contexts/AuthContext';

const Login = () => {
  const { token } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { Title } = Typography;
  const { width, height } = useHandleResize();
  const [currentForm, setCurrentForm] = useState('login');
  const { location } = history;
  const { dispatchAPI } = useAuthContext();
  const [code, setCode] = useState('');
  const { from } = (location && location.state) || { from: { pathname: '/' } };
  let idSession = from.pathname.split('/')[3] || location.search.split('=')[1];
  let guestId;
  const [session, setSession] = useState({});
  const [user, setUser] = useState({});

  if (idSession.includes('&')) {
    idSession = idSession.split('&')[0];
  }

  if (from?.search?.includes('guestId')) {
    guestId = from.search.split('=')[1];
  }

  const checkEmailCustomer = email => {
    const list = [];
    (session.customers || []).forEach(customer => {
      list.push({
        ...customer.customer,
        role: customer.ref === 'Company' ? 'company' : 'trainee',
        ref: customer.ref,
        organization: session.organization
      });
      list.push(
        ...customer.trainees_list.map(el => ({
          ...el,
          role: 'trainee',
          ref: 'Trainees',
          organization: session.organization
        }))
      );
    });

    (session.contributors || []).forEach(contributor => {
      list.push({
        ...contributor.contributor,
        role: 'contributor',
        ref: 'Contributor',
        organization: session.organization
      });
    });

    let customer = list.filter(el => el.email === email);
    if (guestId && customer.length) {
      customer = customer.filter(el => el._id === guestId);
    }
    setUser(customer);
    if (customer) return customer;
    return false;
  };

  const forms = {
    login: (
      <LoginForm
        switchForm={f => setCurrentForm(f)}
        checkEmailCustomer={checkEmailCustomer}
        from={from}
        user={user}
        session={session}
      />
    ),
    register: (
      <RegisterForm
        switchForm={f => setCurrentForm(f)}
        checkEmailCustomer={checkEmailCustomer}
        idSession={idSession}
      />
    ),
    forgotPwd: (
      <ForgotPwdForm
        switchForm={f => setCurrentForm(f)}
        sessionId={idSession}
        guestId={guestId}
      />
    ),
    changePwd: (
      <ChangePwdForm
        switchForm={f => setCurrentForm(f)}
        token={token}
        idSession={idSession}
      />
    ),
    createPwd: (
      <CreatePwdForm
        switchForm={f => setCurrentForm(f)}
        checkEmailCustomer={checkEmailCustomer}
        token={token}
        idSession={idSession}
        session={session}
      />
    )
  };

  const getSession = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/extranet/${idSession}?customer=${JSON.stringify([
          { path: 'contributors', populate: { path: 'contributor' } },
          {
            path: 'customers',
            populate: [{ path: 'customer' }, { path: 'trainees_list' }]
          }
        ])}`
      });
      setSession(data);
    } catch (e) {
      message.error(e.status);
    }
  };

  useEffect(() => {
    getSession();
  }, [idSession, currentForm]);

  if (location.search) {
    const searchString = location.search.split('?')[1];
    const searchParts = searchString.split('&');

    searchParts.forEach(part => {
      const partMap = part.split('=');

      if (partMap[0] === 'code') {
        const newCode = partMap[1];
        if (code === '') setCode(newCode);
      }
    });
  }

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  return (
    <div>
      <Layout style={{ minHeight: '100vh', display: 'inherit' }}>
        <Row>
          <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 16 }}>
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
              <img style={{ width: '80%' }} alt="logo" src={Logo} />
            </Row>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            style={{ backgroundColor: 'var(--componentBackground)' }}
          >
            <Row
              justify="center"
              align={width >= 768 && 'middle'}
              style={{ height: '100vh' }}
            >
              {width < 768 && height > 550 && (
                <img alt="logo" style={{ width: '60%' }} src={Logo} />
              )}
              {width < 768 && height <= 550 && (
                <img alt="logo" style={{ width: '60%' }} src={Logo} />
              )}
              <Col xxl={14} xs={20} style={{ textAlign: 'center' }}>
                <Title style={{ textAlign: 'center', marginBottom: 40 }}>
                  {t(`login.title.${currentForm}`)}
                </Title>
                {forms[currentForm]}
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </div>
  );
};

Login.propTypes = {
  location: PropTypes.shape({}).isRequired
};

export default Login;

