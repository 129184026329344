import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../../components';
import {
  Card,
  Col,
  Divider,
  List,
  Popconfirm,
  Skeleton,
  message,
  Row,
  PageHeader,
  Button
} from 'antd';
import {
  DownloadOutlined,
  FileOutlined,
  RightOutlined,
  WarningOutlined
} from '@ant-design/icons';
import listContent from './listContent';
import {
  TeamIcon,
  ResultsIcon,
  ResourcesIcon,
  GoalIcon
} from '../../../utils/constants/customIcon';
import * as moment from 'moment';

const Program = ({ program }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  return (
    <>
      <PageHeader
        title={t('programs.show.name', {
          name: program.name
        })}
      />
      <ContentCustom>
        <Skeleton loading={!program} active>
          <DescriptionList data={listContent(program)} translate />
        </Skeleton>
      </ContentCustom>
      {program.files.length !== 0 && (
        <>
          <Divider orientation="left" style={{ marginTop: 24 }}>
            <PageHeader title={t('programs.form.program_file')} />
          </Divider>
          <Card>
            <List
              dataSource={program.files.filter(file => file.uploaded)}
              split={false}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<FileOutlined />}
                    title={
                      <span>
                        {item.filename} version du{' '}
                        {moment(item.date).format('DD-MM-YYYY')}
                      </span>
                    }
                  />
                  <span>
                    <Button
                      type="link"
                      onClick={() => {
                        downloadFile(item._id, item.filename);
                      }}
                    >
                      <DownloadOutlined style={{ fontSize: 18 }} />
                    </Button>
                  </span>
                </List.Item>
              )}
            />
          </Card>
        </>
      )}
      {(program?.educational_goals || []).length ? (
        <>
          <Divider orientation="left" style={{ marginTop: 24 }}>
            <PageHeader title={t('programs.form.educational_goals')} />
          </Divider>
          <ContentCustom>
            <List
              dataSource={program.educational_goals}
              split={false}
              renderItem={goal => (
                <List.Item style={{ justifyContent: 'flex-start' }}>
                  <span style={{ marginRight: 10 }}>
                    <GoalIcon />
                  </span>
                  <span>{goal}</span>
                </List.Item>
              )}
            />
          </ContentCustom>
        </>
      ) : null}

      {program.execution_track.team_description ||
        !!program.execution_track.results_track.length ||
        (!!program.execution_track.educational_resources.length && (
          <>
            <Divider />
            <ContentCustom>
              <Row style={{ margin: '0 -20px' }}>
                {program && program.execution_track.team_description && (
                  <Col span={8}>
                    <Card
                      bordered={false}
                      className="program-meta-card"
                      title={
                        <>
                          <TeamIcon />
                          <span>{t('programs.form.team_description')}</span>
                        </>
                      }
                    >
                      <span>{program?.execution_track?.team_description}</span>
                    </Card>
                  </Col>
                )}
                {program && !!program.execution_track.results_track.length && (
                  <Col span={8}>
                    <Card
                      bordered={false}
                      className="program-meta-card"
                      title={
                        <>
                          <ResultsIcon />
                          <span>{t('programs.form.results_track')}</span>
                        </>
                      }
                    >
                      <List
                        dataSource={
                          program && program?.execution_track?.results_track
                        }
                        split={false}
                        renderItem={item => <List.Item>{item}</List.Item>}
                      />
                    </Card>
                  </Col>
                )}
                {program &&
                  !!program.execution_track.educational_resources.length && (
                    <Col span={8}>
                      <Card
                        bordered={false}
                        className="program-meta-card"
                        title={
                          <>
                            <ResourcesIcon />
                            <span>
                              {t('programs.form.educational_resources')}
                            </span>
                          </>
                        }
                      >
                        <List
                          dataSource={
                            program?.execution_track?.educational_resources
                          }
                          split={false}
                          renderItem={item => <List.Item>{item} </List.Item>}
                        />
                      </Card>
                    </Col>
                  )}
              </Row>
            </ContentCustom>
          </>
        ))}

      {program.quality && (
        <>
          <Divider orientation="left" style={{ marginTop: 24 }}>
            <PageHeader title={t('programs.form.quality')} />
          </Divider>
          <Card>{program.quality}</Card>
        </>
      )}
    </>
  );
};

Program.propTypes = {
  program: PropTypes.shape({}).isRequired
};
export default Program;

