import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Radio, Table } from 'antd';

const ChoiceGridTable = ({
  indexQuestion,
  responses,
  setResponses,
  questionsList,
  options,
  purpose
}) => {
  const [checked, setChecked] = useState({});
  const [optionNotes, setOptionNotes] = useState([]);

  useEffect(() => {
    const element = [];
    if (purpose === 'show') {
      responses.responses[indexQuestion].grid_responses_list.map(res => {
        element[res.question] = res.response;
      });
    } else {
      questionsList.map(el => {
        element[el._id] = 0;
      });
    }
    setChecked(element);
  }, [questionsList]);

  const checkResponses = (idQuestion, idAnswer) => {
    const respList = { ...responses };

    // remove old response
    respList.responses[indexQuestion].grid_responses_list.forEach(
      (el, index) => {
        if (el.question.toString() === idQuestion.toString() && el.response) {
          checked[idQuestion] = 0;
        }
      }
    );

    // add the new response
    respList.responses[indexQuestion].grid_responses_list.forEach(
      (el, index) => {
        if (el.question.toString() === idQuestion.toString()) {
          checked[idQuestion] = idAnswer;
          respList.responses[indexQuestion].grid_responses_list[
            index
          ].response = idAnswer;
          respList.responses[indexQuestion].grid_responses_list[index].mark =
            optionNotes[idAnswer];
        }
      }
    );
    setResponses(respList);
    setChecked(checked);
  };

  useEffect(() => {
    if (purpose !== 'show') {
      const list = [];
      const note = 10 / options.length;
      let mark = note;
      options.map(el => {
        list[el._id] = mark;
        mark += note;
      });
      setOptionNotes(list);
    }
  }, [options]);

  const columns = [
    {
      render: question => <span>{question.title}</span>,
      width: 500,
      key: 'title'
    },
    ...options.map(opt => ({
      title: () => (
        <div
          style={{
            maxWidth: '400px',
            overflowY: 'auto',
            whiteSpace: 'normal',
            wordWrap: 'break-word'
          }}
        >
          {opt.title}
        </div>
      ),
      key: opt.title,
      render: question => (
        <Radio
          disabled={purpose === 'show'}
          checked={checked[question._id] === opt._id}
          onClick={() => checkResponses(question._id, opt._id)}
        />
      )
    }))
  ];

  return (
    <>
      <Table
        pagination={false}
        dataSource={questionsList}
        columns={columns}
        scroll={{ x: 'max-content' }}
      />
    </>
  );
};

export default ChoiceGridTable;

ChoiceGridTable.propTypes = {
  indexQuestion: PropTypes.number.isRequired,
  responses: PropTypes.shape({ responses: PropTypes.shape({}) }).isRequired,
  setResponses: PropTypes.func.isRequired,
  questionsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  purpose: PropTypes.string.isRequired
};
