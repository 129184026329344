import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Card, Divider, List, Tag } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { routes } from '../../../utils/constants/routes';
import useAuthContext from '../../../contexts/AuthContext';

import {
  PreTrainingQuizIcon,
  HotQuizIcon,
  ColdQuizIcon,
  ManagerQuizIcon,
  ContributorQuizIcon,
  EndTrainingQuizIcon
} from '../../../utils/constants/customIcon';

const Quizzes = ({
  quizzesResponses,
  quizzes,
  quizzesModel,
  setQuizzesModel
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useAuthContext();

  const checkIfExist = id => {
    let link = `answers/${id}`;
    quizzesResponses &&
      quizzesResponses.forEach(response => {
        if (
          response.quiz?._id.toString() === id.toString() &&
          response.user.toString() === user._id.toString()
        ) {
          link = `show/${response._id}`;
        }
      });

    return link;
  };

  return (
    <>
      {user.role === 'trainee' &&
        !!quizzes.filter(el => el.type === 'training_quiz').length &&
        !!quizzes.find(el => el.type === 'training_quiz').questions.length &&
        (quizzes.find(el => el.type === 'training_quiz').visibility ||
          checkIfExist(
            quizzes.find(el => el.type === 'training_quiz')._id
          )) && (
          <>
            <Divider orientation="left">
              <span>{t(`sessions.quizzes.models.training_quiz`)}</span>
            </Divider>
            <Card>
              <List
                dataSource={quizzes.filter(el => el.type === 'training_quiz')}
                renderItem={item => {
                  const link = checkIfExist(item._id);
                  return (
                    <>
                      {(item.visibility || checkIfExist(item._id)) && (
                        <List.Item
                          onDoubleClick={() =>
                            history.push(
                              `${routes.SESSIONS}/show/${item.session._id}/training_quiz/${link}`
                            )
                          }
                          actions={[
                            <Link
                              to={{
                                pathname: `${routes.SESSIONS}/show/${item.session._id}/training_quiz/${link}`
                              }}
                            >
                              {link.split('/')[0] === 'show' ? (
                                <EyeOutlined style={{ fontSize: 18 }} />
                              ) : (
                                <EditOutlined style={{ fontSize: 18 }} />
                              )}
                            </Link>
                          ]}
                        >
                          <List.Item.Meta
                            avatar={<Avatar icon={<EndTrainingQuizIcon />} />}
                            title={
                              <>
                                <span>{item.name}</span>
                                {link.split('/')[0] === 'show' && (
                                  <Tag style={{ marginLeft: 10 }} color="green">
                                    Terminé
                                  </Tag>
                                )}
                              </>
                            }
                          />
                        </List.Item>
                      )}
                    </>
                  );
                }}
              />
            </Card>
          </>
        )}
      <List
        dataSource={quizzesModel}
        renderItem={item => (
          <>
            {!!quizzes.filter(el => el.type === item).length &&
              !!quizzes.find(el => el.type === item).questions.length &&
              item !== 'training_quiz' && (
                <>
                  <Divider orientation="left">
                    <span>{t(`sessions.quizzes.models.${item}`)}</span>
                  </Divider>
                  <Card>
                    <List
                      dataSource={quizzes.filter(el => el.type === item)}
                      renderItem={item => {
                        const link = checkIfExist(item._id);
                        return (
                          <List.Item
                            onDoubleClick={() =>
                              history.push(
                                `${routes.SESSIONS}/show/${item.session._id}/quizzes/${link}`
                              )
                            }
                            actions={[
                              <Link
                                to={{
                                  pathname: `${routes.SESSIONS}/show/${item.session._id}/quizzes/${link}`
                                }}
                              >
                                {link.split('/')[0] === 'show' ? (
                                  <EyeOutlined style={{ fontSize: 18 }} />
                                ) : (
                                  <EditOutlined style={{ fontSize: 18 }} />
                                )}
                              </Link>
                            ]}
                          >
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  icon={
                                    item.type === 'pre_training' ? (
                                      <PreTrainingQuizIcon />
                                    ) : item.type === 'post_training_j' ? (
                                      <HotQuizIcon />
                                    ) : item.type === 'post_training_month' ? (
                                      <ColdQuizIcon />
                                    ) : item.type === 'managers_quizzes' ? (
                                      <ManagerQuizIcon />
                                    ) : (
                                      item.type === 'contributors_quizzes' && (
                                        <ContributorQuizIcon />
                                      )
                                    )
                                  }
                                />
                              }
                              title={
                                <>
                                  <span>{item.name}</span>
                                  {link.split('/')[0] === 'show' && (
                                    <Tag
                                      style={{ marginLeft: 10 }}
                                      color="green"
                                    >
                                      Terminé
                                    </Tag>
                                  )}
                                </>
                              }
                            />
                          </List.Item>
                        );
                      }}
                    />
                  </Card>
                </>
              )}
          </>
        )}
      />
    </>
  );
};

export default Quizzes;
