export const routes = {
  HOME: '/',
  SESSIONS: '/sessions'
};

export const subRoutes = {

};

export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
};
