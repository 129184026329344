import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import usePwdPattern from '../../utils/pwdPattern';

const CreatePwdForm = ({ switchForm, checkEmailCustomer, session }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setLoading] = useState(false);

  const createPwd = async (body, userRole) => {
    setLoading(true);

    try {
      const { data } = await dispatchAPI('GET_USER_BY_EMAIL', {
        email: body.username,
        userRole,
        session
      });
      if (data) {
        if (data[0].verifiedEmail) {
          setLoading(false);
          return message.error(t('errors.message.verifiedEmail'), 10);
        }
        try {
          await dispatchAPI('CREATE_USER_PWD', {
            // eslint-disable-next-line no-underscore-dangle
            url: `register?id=${data[0]._id}&userRole=${userRole}`,
            body: { ...body, verifiedEmail: true, role: 'guests:GUEST' }
          });
          message.success(t('login.passwordChanged'));
          switchForm('login');
        } catch (e) {
          message.error(t('login.createPwdEmailError'));
          setLoading(false);
        }
      }
    } catch (e) {
      message.error(e);
    }
    return null;
  };

  const handleSubmit = async values => {
    const customer = checkEmailCustomer(values.username);

    if (customer.length) {
      const userRole = customer[0].role;
      await createPwd(values, userRole);
    } else {
      message.error(t('errors.message.wrongEmail'), 10);
    }
  };

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item
        name="username"
        rules={[{ required: true, message: t('login.usernameMissing') }]}
      >
        <Input
          prefix={
            <UserOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.username')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: t('login.pwdMissing') },
          ...usePwdPattern()
        ]}
        hasFeedback
      >
        <Input.Password
          prefix={
            <LockOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.password')}
        />
      </Form.Item>
      <Form.Item
        dependencies={['password']}
        hasFeedback
        name="confirm"
        rules={[
          {
            required: true,
            message: t('login.pwdMissing')
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(t('login.pwdNotMatching'));
            }
          })
        ]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder={t('login.confirmPassword')}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          {t('login.createPwd')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreatePwdForm;

CreatePwdForm.propTypes = {
  switchForm: PropTypes.func.isRequired,
  checkEmailCustomer: PropTypes.func.isRequired,
  session: PropTypes.string.isRequired
};

