import React, { useState, useEffect } from 'react';
import { Card, List, Avatar, Divider, Collapse, Col, Row, Button } from 'antd';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AttendanceTraineesRecordTable from './AttendanceTraineesRecordTable';
import useAuthContext from '../../contexts/AuthContext';
import { routes } from '../../utils/constants/routes';

const { Panel } = Collapse;

const TraineesList = ({ trainees, modules, quizzesResponses }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { user } = useAuthContext();
  const [slots, setSlots] = useState([]);
  const [test, setTest] = useState({});

  useEffect(() => {
    const list = [];
    modules.forEach(module => {
      module.slots.date.map(date => {
        date.sub_slots.map(subSlot => {
          list.push({
            id: subSlot._id,
            idModule: module._id,
            idDate: date._id,
            idSubSlot: subSlot._id,
            module: module.name,
            date: date.date,
            type: subSlot.type,
            signings: subSlot.signings
          });
        });
      });
    });
    setSlots(list);
  }, [modules]);

  useEffect(() => {
    const test2 = {};
    trainees.forEach(trainee => {
      test2[trainee._id] = {};
      test2[trainee._id].pre = 0;
      test2[trainee._id].total = 0;
      slots.forEach(slot => {
        if (slot.signings.map(el => el.user).includes(trainee._id)) {
          test2[trainee._id].pre += 1;
          test2[trainee._id].total += 1;
        } else {
          test2[trainee._id].total += 1;
        }
      });
    });
    setTest(test2);
  }, [slots]);

  return (
    <>
      <Divider orientation="left">
        <span>{t(`Présence`)}</span>
      </Divider>
      <Card>
        <AttendanceTraineesRecordTable
          modules={modules}
          trainees={trainees}
          slots={slots}
          setSlots={setSlots}
          test={test}
          setTest={setTest}
        />
      </Card>
      <Divider orientation="left">
        <span>{t(`Liste des stagiaires`)}</span>
      </Divider>
      <Card style={{ marginBottom: 16 }}>
        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={trainees}
          renderItem={item => (
            <List.Item>
              <Card
                bordered={false}
                size="small"
                className="trainee-list-card"
                cover={
                  <Avatar
                    style={{ margin: 'auto' }}
                    Avatar
                    size={64}
                    icon={<UserOutlined />}
                  />
                }
              >
                <Collapse ghost>
                  <Panel
                    key={item._id}
                    header={`${item.first_name} ${item.last_name}`}
                  >
                    <Row>
                      <Col span={12}>Présence:</Col>
                      <Col span={12}>
                        {test[item._id] && test[item._id].pre} /
                        {test[item._id] && test[item._id].total}
                      </Col>
                    </Row>
                    {user.ref === 'Contributor' &&
                      !!quizzesResponses.filter(
                        response =>
                          response.quiz?.session === id &&
                          response.user === item._id
                      ).length && (
                        <Row>
                          <Button
                            className="button-show-response"
                            type="link"
                            onClick={() =>
                              history.push(
                                `${routes.SESSIONS}/show/${id}/trainee/quizzes/show/${item._id}`
                              )
                            }
                          >
                            Afficher les réponses aux questionnaires
                          </Button>
                        </Row>
                      )}
                  </Panel>
                </Collapse>
              </Card>
            </List.Item>
          )}
        />
      </Card>
    </>
  );
};

TraineesList.propTypes = {
  trainees: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
export default TraineesList;
