import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';

const Docuements = ({ files, downloadFile, downloadCGVFile }) => {
  const [types, setTypes] = useState([]);
  const { t } = useTranslation();
  const { user } = useAuthContext();

  useEffect(() => {
    const existant = [];
    let index = 0;
    const tab = files.map(el => {
      if (!existant.includes(el.type)) {
        existant.push(el.type);
        index += 1;
        return {
          key: index,
          type: el.type
        };
      }
      return null;
    });
    setTypes(tab.filter(el => el !== null));
  }, [files]);

  const columns = [
    {
      title: 'Nom du fichier',
      dataIndex: 'filename',
      key: 'filename'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            downloadFile(record._id, record.filename);
          }}
        >
          <DownloadOutlined style={{ fontSize: 18 }} />
        </Button>
      )
    }
  ];

  const expandedRowRender = value => {
    const uniqueFile = {};
    const filesWithSameType = files.filter(el => el.type === value.type);
    filesWithSameType.forEach(file => {
      if (!uniqueFile[file.type] || file.date > uniqueFile[file.type].date) {
        uniqueFile[file.type] = file;
      }
    });

    return (
      <Table
        columns={columns}
        dataSource={
          value.type !== 'autres'
            ? Object.values(uniqueFile)
            : files.filter(el => !el.user)
        }
        pagination={false}
      />
    );
  };
  return (
    <>
      <Divider orientation="left">
        <span>{t(`Documents`)}</span>
      </Divider>
      <Table
        columns={[
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: type => <>{t(`documents.${type}`)}</>
          }
        ]}
        dataSource={types}
        expandable={{ expandedRowRender }}
        pagination={false}
      />
      {user.role === 'company' && (
        <>
          <Divider orientation="left">
            <span>{t(`Télécharger les CGV`)}</span>
          </Divider>
          <Card>
            <Button
              type="primary"
              onClick={async () => {
                await downloadCGVFile();
              }}
            >
              {t(`CGV`)}
            </Button>
          </Card>
        </>
      )}
    </>
  );
};

export default Docuements;

Docuements.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  downloadFile: PropTypes.func.isRequired,
  downloadCGVFile: PropTypes.func.isRequired
};

Docuements.defaultProps = {
  files: []
};
