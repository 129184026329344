import React from 'react';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t('home.title')} />
      <ContentCustom />
    </>
  );
};

export default Home;
