/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Alert, Tree } from 'antd';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import SigningModal from './signings/SigningModal';
import useAuthContext from '../../contexts/AuthContext';
import setTreeData from './utils/setTreeData';

const ModulesTree = ({
  reload,
  setReload,
  modules,
  setDateStart,
  setDateEnd,
  purpose
}) => {
  const { user } = useAuthContext();
  const [showModale, setShowModale] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [slots, setSlots] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [subSlot, setSubSlot] = useState({});

  const onExpand = expandedKeysValue => {
    setExpandedKeys(expandedKeysValue);
  };

  const setStartEndDates = () => {
    if (purpose === 'agenda') {
      let start = '';
      let end = '';
      modules.forEach(module => {
        if (start === '' && end === '') {
          start = module.slots.start_date;
          end = module.slots.end_date;
        } else {
          if (moment(module.slots.start_date).isBefore(moment(start))) {
            start = module.slots.start_date;
          }
          if (moment(module.slots.end_date).isAfter(moment(end))) {
            end = module.slots.end_date;
          }
        }
      });
      setDateStart(start);
      setDateEnd(end);
    }
  };

  const onCheck = keys => {
    let item = {};
    modules.forEach(module => {
      module.slots.date.forEach(el => {
        el.sub_slots.forEach(subSlot => {
          if (
            subSlot._id.toString() ===
            keys[keys.length ? keys.length - 1 : 0].toString()
          ) {
            item = {
              ...subSlot,
              date: el.date,
              idModule: module._id,
              idSlot: el._id
            };
          }
        });
      });
    });

    setCheckedKeys(keys);
    setSubSlot(item);
    setShowModale(!showModale);
  };

  useEffect(() => {
    const list = [];
    modules.forEach(module => {
      list.push(module._id);
      module.slots.date.forEach(date => {
        list.push(date._id);
      });
    });
    setExpandedKeys(list);
    setStartEndDates();
  }, [modules]);

  useEffect(() => {
    setTreeData({
      user,
      modules,
      purpose,
      moment,
      checkedKeys,
      setSlots
    });
  }, [modules, reload]);

  return (
    <>
      <SigningModal
        reload={reload}
        setReload={setReload}
        modules={modules}
        setCheckedKeys={setCheckedKeys}
        checkedKeys={checkedKeys}
        setSubSlot={setSubSlot}
        subSlot={subSlot}
        setShowModale={setShowModale}
        showModale={showModale}
      />
      {purpose === 'signings' && !slots.length && (
        <Alert message="Aucune date ne vous a été affectée" type="warning" />
      )}
      <Tree
        onExpand={onExpand}
        checkable={purpose !== 'agenda'}
        disabled={purpose !== 'agenda' && user.role === 'admin'}
        checkedKeys={checkedKeys}
        expandedKeys={expandedKeys}
        treeData={slots}
        onCheck={onCheck}
      />
    </>
  );
};

ModulesTree.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setDateStart: PropTypes.func.isRequired,
  setDateEnd: PropTypes.func.isRequired,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  purpose: PropTypes.string.isRequired
};

ModulesTree.defaultProps = {
  setReload: null,
  reload: false
};

export default ModulesTree;
