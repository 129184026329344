import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { PropTypes } from 'prop-types';
import { routes } from '../../utils/constants/routes';
import useAuthContext from '../../contexts/AuthContext';

const Extranet = ({ id }) => {
  const { setSession, dispatchAPI, setUserSession } = useAuthContext();
  const history = useHistory();
  const [token, setToken] = useState();
  const getToken = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `extranet/${id}?populate=session,user, session.program`
      });
      setToken(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getToken();
    })();
  }, []);
  useEffect(() => {
    if (token) {
      setSession(token.token);
      setUserSession({
        _id: token.user?._id,
        name: token.user?.name,
        first_name: token.user?.first_name,
        last_name: token.user?.last_name,
        role: token.role,
        ref: token.ref,
        organization: token.user.organization
      })
      history.push(`${routes.SESSIONS}/show/${token.session._id}`, token);
    }
  }, [token]);

  return <></>;
};

Extranet.propTypes = {
  id: PropTypes.string.isRequired
};

export default Extranet;
