import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();


  return [
    {
      title: t('sessions.form.name'),
      dataIndex: 'name',
      sorter: true
    },
  ];
};

export default useColumns;
