import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Input, Modal, PageHeader, message } from 'antd';
import * as moment from 'moment';
import { formItemLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import SignaturePad from 'react-signature-canvas';

const SigningModal = ({
  reload,
  setReload,
  checkedKeys,
  setCheckedKeys,
  modules,
  setSubSlot,
  subSlot,
  showModale,
  setShowModale
}) => {
  const { user, dispatchAPI } = useAuthContext();
  const sigCanvas = useRef({});
  const { id } = useParams();

  const handleCancel = () => {
    sigCanvas.current.clear();
    const list = checkedKeys.filter(
      el => el.toString() !== subSlot._id.toString()
    );
    setShowModale(false);
    setCheckedKeys(list);
  };

  const updateModule = async body => {
    try {
      await dispatchAPI('PATCH', {
        url: `/module/${subSlot.idModule}/${subSlot.idSlot}/${subSlot._id}`,
        body
      });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const onFinish = async () => {
    let sub = {};
    if (sigCanvas.current.isEmpty()) {
      message.error('Veuillez signer pour valider votre émargement');
      return;
    }
    const image = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    if (subSlot.signings) {
      sub = {
        ...subSlot,
        signings: [
          ...subSlot.signings,
          {
            user: user._id,
            ref: user.ref,
            has_signed: true,
            signature: image
          }
        ]
      };
    } else {
      sub = {
        ...subSlot,
        signings: [
          {
            user: user._id,
            ref: user.ref,
            has_signed: true,
            signature: image
          }
        ]
      };
    }
    const date = modules
      .filter(el => el._id === subSlot.idModule)[0]
      .slots.date.find(
        date => date._id.toString() === subSlot.idSlot.toString()
      );
    const subSlots = date.sub_slots.filter(
      el => el._id.toString() !== subSlot._id.toString()
    );
    let body = {};
    if (subSlot.type === 'Matin') {
      body = {
        ...date,
        sub_slots: [sub, ...subSlots]
      };
    } else {
      body = {
        ...date,
        sub_slots: [...subSlots, sub]
      };
    }
    body.user = user;
    body.session = id;
    console.log(id)

    sigCanvas.current.clear();
    setReload(!reload);
    setShowModale(false);
    await updateModule(body);
  };

  return (
    <>
      <Modal visible={showModale} onCancel={handleCancel} footer={false}>
        <PageHeader title={'Emargements'} />
        <div style={{ textAlign: 'center' }}>
          <span>{`Session de formation du ${moment(subSlot.date).format(
            'dddd'
          )} ${moment(subSlot.date).format('LL')} ${subSlot.type}`}</span>
          <br />
          <span>{`${subSlot.start_time} - ${subSlot.end_time}`}</span>
        </div>
        <div style={{ margin: '20px 0 44px 0' }}>
          <p>Veuillez signer à l'intérieur du cadre :</p>
          <div
            style={{
              border: '1px solid #2b2b2b',
              width: '100%',
              height: 200,
              margin: 'auto'
            }}
          >
            <SignaturePad
              canvasProps={{ width: 425, height: 200, className: 'sigCanvas' }}
              ref={sigCanvas}
            />
          </div>
          <Button
            type="add"
            onClick={() => onFinish()}
            style={{ float: 'right', marginTop: 16 }}
          >
            Enregistrer la signature
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SigningModal;

