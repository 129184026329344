import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  List,
  Card,
  Checkbox,
  Col,
  message,
  Rate,
  Row,
  Button,
  Alert
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Radio from 'antd/es/radio/radio';
import TextArea from 'antd/es/input/TextArea';
import useAuthContext from '../../../contexts/AuthContext';
import { PageHeaderCustom } from '../../../components';
import { routes } from '../../../utils/constants/routes';
import ChoiceGridTable from './ChoiceGridTable';
import getQuizResponses from './utils/getQuizeResponses';
import getQuiz from './utils/getQuiz';
import createQuizResponse from './utils/createQuizResponse';

const AnswerQuiz = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { dispatchAPI, user } = useAuthContext();
  const [quiz, setQuiz] = useState({});
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState({});
  const [quizzesResponses, setQuizzesResponses] = useState([]);
  const [responsesErrors, setResponsesErrors] = useState([]);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    (async () => {
      await getQuiz({ dispatchAPI, setQuiz, id });
    })();
  }, []);

  useEffect(() => {
    quiz.questions && setQuestions(quiz.questions);
  }, [quiz]);

  useEffect(() => {
    const listResponses = {
      quiz: id,
      user: user._id,
      ref: user.ref,
      trainee_mark: 0,
      responses: []
    };
    questions.forEach(question => {
      if (question.type === 'CHOICE_GRID') {
        listResponses.responses.push({
          question: question._id,
          mark: 0,
          grid_responses_list: []
        });
      } else {
        listResponses.responses.push({
          question: question._id,
          response: []
        });
      }
    });
    questions.forEach(question => {
      question &&
        question.questions_list.forEach(el => {
          listResponses.responses.map((response, index) => {
            if (response.question.toString() === question._id.toString()) {
              listResponses.responses[index].grid_responses_list.push({
                question: el._id
              });
            }
          });
        });
    });

    setResponses(listResponses);
  }, [questions]);

  const calculateNote = () => {
    const newResponses = { ...responses };
    let gridQuestions = 0;
    let quizNote = 0;
    responses.responses &&
      responses.responses.forEach((response, index) => {
        let grid_note = 0;
        let baseMark = 0;
        if (
          response.grid_responses_list &&
          response.grid_responses_list.length
        ) {
          gridQuestions += 1;
          response.grid_responses_list.forEach((el, indexEl) => {
            grid_note += el.mark;
            baseMark += 1;
          });
          newResponses.responses[index].mark = grid_note / baseMark;
          quizNote += grid_note / baseMark;
        } else {
          gridQuestions += 1;
          quizNote += Number(response.response[0]);
        }
      });
    newResponses.trainee_mark = quizNote / gridQuestions;
    setResponses(newResponses);
    return newResponses;
  };

  const addResponse = (type, index, value) => {
    const listResponses = responses;
    if (type === 'MULTI_CHOICE') {
      listResponses.responses[index].response.push(value);
    } else {
      listResponses.responses[index].response = [value];
    }
    setResponses(listResponses);
  };

  const checkRender = (type, options, indexQuestion, questionsList) => {
    switch (type) {
      case 'UNIQUE_CHOICE':
        {
          return (
            <>
              <Radio.Group
                onChange={value =>
                  addResponse(type, indexQuestion, value.target.value)
                }
              >
                {options.map(el => (
                  <Row>
                    <Col span={24}>
                      <Radio value={el._id}>{el.title} </Radio>
                    </Col>
                  </Row>
                ))}
              </Radio.Group>
            </>
          );
        }
        break;
      case 'MULTI_CHOICE':
        {
          return (
            <>
              {options.map(el => (
                <Row>
                  <Col span={24}>
                    <Checkbox
                      value={el._id}
                      onChange={value =>
                        addResponse(type, indexQuestion, value.target.value)
                      }
                    />
                    <span> {el.title}</span>
                  </Col>
                </Row>
              ))}
            </>
          );
        }
        break;
      case 'TEXT_AREA':
        {
          return (
            <TextArea
              onChange={value =>
                addResponse(type, indexQuestion, value.target.value)
              }
            />
          );
        }
        break;
      case 'SCORE':
        {
          return (
            <Rate onChange={value => addResponse(type, indexQuestion, value)} />
          );
        }
        break;
      case 'CHOICE_GRID': {
        return (
          <ChoiceGridTable
            indexQuestion={indexQuestion}
            responses={responses}
            setResponses={setResponses}
            options={options}
            questionsList={questionsList}
          />
        );
      }
    }
  };

  const submitQuiz = async () => {
    const listErrors = [];
    const body = calculateNote();
    responses.responses.map(response => {
      if (response.response && !response.response.length) {
        listErrors.push(response.question);
      } else if (response.grid_responses_list) {
        response.grid_responses_list.map(el => {
          if (!el.response.length) {
            listErrors.push(response.question);
          }
        });
      }
    });
    if (listErrors.length) {
      message.error(
        t('sessions.quizzes.message.missing_answers', {
          missing_answers: listErrors.length
        })
      );
    } else {
      setRedirect(!redirect);
      await createQuizResponse({ body, dispatchAPI });
      await getQuizResponses({ setQuizzesResponses, dispatchAPI });
      setRedirect(!redirect);
    }
    setResponsesErrors(listErrors);
  };

  useEffect(() => {
    if (redirect) {
      const response = quizzesResponses[quizzesResponses.length - 1];
      history.push(
        `${routes.SESSIONS}/show/${quiz.session}/quizzes/show/${response._id}`
      );
    }
  }, [quizzesResponses]);

  return (
    <>
      <PageHeaderCustom
        title={t('sessions.quizzes.title.name', {
          name: quiz && quiz.name
        })}
      />
      <Card style={{ marginTop: '20px' }} className="quiz-detail-wrapper">
        <List
          grid={{ column: 1 }}
          dataSource={questions}
          renderItem={(item, index) => (
            <List.Item>
              <Card bordered={false} title={item.title}>
                {responsesErrors.includes(item._id) ? (
                  <Alert
                    type="error"
                    message={checkRender(
                      item.type,
                      item.answer_options,
                      index,
                      item.questions_list
                    )}
                  />
                ) : (
                  checkRender(
                    item.type,
                    item.answer_options,
                    index,
                    item.questions_list
                  )
                )}
              </Card>
            </List.Item>
          )}
        />
        <Button type="add" onClick={submitQuiz}>
          {t('sessions.quizzes.button.sendResponses')}
        </Button>
      </Card>
    </>
  );
};

export default AnswerQuiz;
