import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Sessions from './Sessions';
import SessionRedirection from './SessionRedirection';
import AnswerQuiz from './quizzes/AnswerQuiz';
import DetailQuiz from './quizzes/DetailQuiz';
import AnswerTrainingQuiz from './quizzes/AnswerTrainingQuiz';
import TraineeDetailsQuizzes from './quizzes/TraineeDetailsQuizzes';

const SessionsRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/show/:sessionId/training_quiz/answers/:id`}
        component={AnswerTrainingQuiz}
      />
      <Route
        path={`${path}/show/:id/quizzes/answers/:id`}
        component={AnswerQuiz}
      />
      <Route
        path={`${path}/show/:id/training_quiz/show/:id`}
        render={() => <DetailQuiz purpose="training_quiz" />}
      />
      <Route
        path={`${path}/show/:id/quizzes/show/:id`}
        component={DetailQuiz}
      />
      <Route
        path={`${path}/show/:id/trainee/quizzes/show/:id`}
        component={TraineeDetailsQuizzes}
      />
      <Route path={`${path}/show/:id`} component={SessionRedirection} />
      <Route path={`${path}`} render={() => <Sessions />} />
    </Switch>
  );
};

export default SessionsRouter;
