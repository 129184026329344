import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Button, Layout, Menu, message, Typography } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import HeaderDropdown from '../../HeaderDropdown/HeaderDropdown';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Header } = Layout;
const { Title } = Typography;

const LogoDiv = styled.div`
  height: 100%;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  transition: all 0.3s;
  z-index: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  img {
    display: inline-block;
    height: 24px;
    vertical-align: middle;
  }
`;

const StyledHeader = styled.div`
  background: var(--menuDropdownBackground);
  box-shadow: 0 1px 8px rgba(43, 43, 43, 0.15);
  padding: 0;
  z-index: 8;
  width: 100%;
  position: fixed;
`;

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDropdownContainer = styled.span`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  transition: all 0.3s;
  :hover {
    background: var(--contrastBackground);
  }
  button {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    p {
      margin-bottom: 0;
    }
  }
`;

const StyledFullNameSpan = styled.span`
  color: var(--textColor);
  font-weight: bold;
`;

const StyledRoleP = styled.p`
  font-size: 11px;
  color: var(--textColor);
`;

const HeaderLayout = ({ themeLogo }) => {
  const { user, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [organization, setOrganization] = useState({});
  const [logo, setLogo] = useState('');
  const { notification } = useErrorMessage();

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logoutSuccess'));
    }
  };

  const getOrganizations = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/organizations/${user?.organization}`
      });
      setOrganization(data);
      setLogo(data.logo);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const profileMenu = (
    <Menu onClick={onProfileMenuClick}>
      {window.innerWidth <= 992 && user && (
        <Menu.Item key="user">
          <Button
            style={{
              width: '100%',
              height: 50
            }}
            type="link"
          >
            <StyledFullNameSpan>
              {`${user.first_name} ${user.last_name}`}
            </StyledFullNameSpan>
            <StyledRoleP>{t(`users.tags.${user.role}`)}</StyledRoleP>
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="logout">
        <LogoutOutlined
          style={{
            fontSize: 16
          }}
        />
        {t('header.menu.user.logout')}
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    getOrganizations(user.organization);
  }, []);

  return (
    <StyledHeader as={Header}>
      <LogoDiv>{logo ? <img alt="Logo" src={logo} /> : null}</LogoDiv>
      {themeLogo && (
        <img
          style={{
            width: 100,
            maxHeight: 50,
            position: 'absolute',
            top: 10,
            left: '50%',
            marginLeft: '-50px'
          }}
          src={themeLogo}
          alt="Company logo"
        />
      )}
      <StyledContainer div={organization}>
        <Title level={5} style={{ fontWeight: 'bold' }}>
          {organization.name}
        </Title>
        <HeaderDropdown overlayMenu={profileMenu}>
          {window.innerWidth <= 992 ? (
            <StyledDropdownContainer>
              <UserOutlined />
            </StyledDropdownContainer>
          ) : (
            <StyledDropdownContainer>
              <Avatar
                size="medium"
                icon={user && user.photo ? '' : <UserOutlined />}
                src={user && user.photo ? user.photo : ''}
              >
                {user && user.name
                  ? `${user.name}`
                  : user.first_name &&
                    `${user.first_name[0]}${user.last_name[0]}`}
              </Avatar>
              <Button type="link">
                <StyledFullNameSpan>
                  {user.name
                    ? user.name
                    : `${user.first_name} ${user.last_name}`}
                </StyledFullNameSpan>
                <StyledRoleP>{t(`users.tags.${user.role}`)}</StyledRoleP>
              </Button>
            </StyledDropdownContainer>
          )}
        </HeaderDropdown>
      </StyledContainer>
    </StyledHeader>
  );
};

HeaderLayout.propTypes = {
  themeLogo: PropTypes.string
};

HeaderLayout.defaultProps = {
  themeLogo: null
};

export default HeaderLayout;

