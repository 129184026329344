import { message } from 'antd';

const getSessionName = async ({ sessionId, dispatchAPI, setSessionName }) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `sessions/extranet/${sessionId}`
    });
    setSessionName(data);
  } catch (e) {
    if (e.response) message.error(e.response.status);
  }
};

export default getSessionName;

