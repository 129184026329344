import { message } from 'antd';

const createQuizResponse = async ({ body, dispatchAPI, t }) => {
  try {
    await dispatchAPI('POST', { url: '/quizzes_responses', body });

    message.success(t('sessions.quizzes.message.success'), 10);
  } catch (e) {
    if (e.response) message.error(e.response.status);
  }
};

export default createQuizResponse;
