import React, { useEffect, useState } from 'react';
import { Collapse, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import { PageHeaderCustom } from '../../../components';
import DetailQuiz from './DetailQuiz';

const { Panel } = Collapse;

const TraineeDetailsQuizzes = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [quizzesResponses, setQuizzesResponses] = useState([]);

  const getQuizzesResponses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `quizzes_responses?populate=quiz,user&userId=${id}`
      });
      setQuizzesResponses(data.filter(response => response?.user?._id === id));
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getQuizzesResponses();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={t('sessions.quizzes.show.name', {
          name: `${quizzesResponses[0]?.user?.first_name} ${quizzesResponses[0]?.user?.last_name}`
        })}
      />
      {quizzesResponses.length > 0 && (
        <Collapse
          defaultActiveKey={[quizzesResponses.length === 1 ? '0' : '']}
          ghost
        >
          {quizzesResponses.map(
            (response, index) =>
              response?.quiz?.name && (
                <Panel
                  key={index}
                  header={
                    <span style={{ fontSize: 16 }}>{response?.quiz?.name}</span>
                  }
                >
                  <DetailQuiz purpose="modal" response={response} />
                </Panel>
              )
          )}
        </Collapse>
      )}
    </>
  );
};

export default TraineeDetailsQuizzes;
